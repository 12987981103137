<template>
  <v-app>
    <div class="promo-layout">
      <Header />
      <div class="content mb-10">
        <v-breadcrumbs  
        divider=">"
        :items="breadcrumbs">
          <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :href="item.href"
              :to="item.to"
              exact
              :disabled="item.disabled"
            >
              <svg v-if="item.text === 'home'" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 0L0 4.54545V10H10V4.54545L5 0ZM5 2.57273L8 5.3V8.18182H6V6.36353H4V8.18182H2V5.3L5 2.57273Z" fill="#858585"/>
              </svg>
              <span v-else>{{ item.text }}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>

        <TopLinks v-if="$route.name === 'PromoList' || 
          $route.name === 'PromoCarousel'" 
        />

        <v-row 
          class=" my-0 promo__row align-start"
          :class="{'pt-8': $vuetify.breakpoint.lgAndUp}"
        >
            <v-col 
            cols="12"
            sm="12"
            md="4"
            class="py-0 promo__aside-wrap"
            >
              <div  class="promo__aside">
                  <div 
                  class="promo__aside-block py-md-5 px-md-4"
                  :class="{'grey lighten-4': $vuetify.breakpoint.lgAndUp}"
                  >
                      <v-btn 
                      text
                      color="#0A467E"
                      :exact="true"
                      :to="{name: 'PromoList', params: {country: this.$route.params.country}}">
                        <v-icon 
                        small
                        class="mr-2">
                          mdi-arrow-left
                        </v-icon>
                        <span class="text-decoration-underline">{{$vuetify.breakpoint.lgAndUp ? 'Вернуться к промостранице' : 'Промостраница'}}</span>
                      </v-btn>
                      <v-btn
                        elevation="0"
                        color="#0A467E"
                        :dark="$vuetify.breakpoint.lgAndUp"
                        tile
                        ref="saveButton"
                        :ripple="false"
                        :block="$vuetify.breakpoint.lgAndUp"
                        :text="!$vuetify.breakpoint.lgAndUp"
                        type="submit"
                        height="44"
                        class="mt-md-10 mb-md-4"
                        :class="{'ml-auto': !$vuetify.breakpoint.lgAndUp}"
                        @click="validate()"
                      >Сохранить
                      </v-btn>
                      <v-btn
                        elevation="0"
                        color="#EB174B"
                        :block="$vuetify.breakpoint.lgAndUp"
                        text
                        :ripple="false"
                        height="44"
                        @click="deleteConfirm = true"
                      >Удалить
                      </v-btn>
                  </div>
              </div>
            </v-col>
             <v-col 
            cols="12"
            sm="12"
            md="8"
           class="py-0"
            >
            <router-view ref="promoDetail"/>
            </v-col>
        </v-row>
       
      </div>
       <Dialog :title="'промоакцию'" v-if="deleteConfirm" @dialogState="deletePromo"/>
      <Footer/>
    </div>  
  </v-app>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import TopLinks from '@/components/promo/TopLinks'
import Dialog from '@/components/help/Dialog.vue';
import { mapActions, mapGetters } from 'vuex'
// import Menu from '../components/promo/Menu.vue'
export default {
  components: { 
    Header,
    Footer,
    TopLinks,
    Dialog,
    // Menu, 
  },
  name: "promoDetail",
  data:(vm) => ({
    submit: false,
    deleteConfirm: false
  }),
  computed: {
    ...mapGetters(['getPromo']),
    country() {
        switch(this.$route.params.country) {
        case 'ru':
            return "Россия"
        case 'ua':
            return "Украина"
        case 'kz':
            return "Казахстан"
        }
    },
    breadcrumbs() {
        return [
            {
              text: "home",
              to: { 
                name: "PromoList", 
                params: { country: this.$route.params.country }
              }
            },
            {
              text: this.country,
              to: { 
                name: "PromoList", 
                params: { country: this.$route.params.country }
              }
            },
            {
              text: "Промостраница",
              to: {
                name: "PromoDetailPromo", 
                params: { country: this.$route.params.country }
              }
            }
        ]
    },
  },
  methods: {
    ...mapActions(['clearPromo', 'fetchDeletePromo']),
    validate() {
      this.$refs.promoDetail.validate()
    },
    deletePromo(state) {
      if(state) {
        this.fetchDeletePromo(this.getPromo.id)
        this.$router.push({name: "PromoList"})
      } else {
        this.deleteConfirm = false
      }
      
    }
  },
  beforeDestroy() {
    this.clearPromo()
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => { 
        this.clearPromo()
        e.preventDefault()
    });
  }
}
</script>

<style scoped lang="scss">
    .promo-layout {
        width: 100%;
        min-height: 100vh;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        overflow-y: auto;
        padding-top: 75px;

        @media screen and (max-width: 600px) {
          padding-top: 47px;
        }

        & .header {
          position: fixed;
          width: 100%;
          left: 0;
          z-index: 310;
        }

        & .header {
          top: 0;
        }
    }

    .promo__aside {
      height: calc(100vh - 75px);
      overflow-y: auto;

      @media screen and (max-width: 960px) {
        margin: 0 -30px;
        overflow: visible;
        height: auto;

        &-block {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          box-shadow: (0px 4px 13px rgba(0, 0, 0, 0.18));
          background-color: #fff;
          overflow-y: auto;
        }
      }

      @media screen and (max-width: 600px) {
          margin: 0 -15px;
      }

    }
    

    .promo__aside-wrap {
      position: sticky;
      top: 0;
      @media screen and (max-width: 960px) {
        // position: relative;
        z-index: 3;
      }
     
    }
</style>

<style lang="scss">
  
</style>